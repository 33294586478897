export default {
  reset(state) {
    // Object.assign(state, STATE);
    state.step = 2;
    state.login = null;
    state.password = null;
    state.username = null;
    state.userId = null;
    state.maskedEmail = null;
    state.maskedPhone = null;
    state.code = [null, null, null, null, null, null];
    state.errors = {};
    state.submitError = null;
    state.loading = false;
  },
  status(state, data) {
    state.status = data;
  },
  step(state, data) {
    state.step = data;
  },
  login(state, data) {
    state.login = data;
  },
  password(state, data) {
    state.password = data;
  },
  username(state, data) {
    state.username = data;
  },
  language(state, data) {
    state.language = data;
  },
  setUserId(state, data) {
    state.userId = data;
  },
  maskedEmail(state, data) {
    state.maskedEmail = data;
  },
  maskedPhone(state, data) {
    state.maskedPhone = data;
  },
  code(state, data) {
    state.code = data;
  },
  errors(state, data) {
    state.errors = data;
    state.submitError = null;
  },
  submitError(state, message) {
    state.submitError = message;
  },
  loading(state, data) {
    state.loading = data;
  },
  needRelogin(state, data) {
    state.needRelogin = data;
  },
  setUser(state, user) {
    state.user = { ...state.user, ...user };
  },
}
