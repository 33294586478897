import Vue from 'vue';
import Router from 'vue-router';
import authRouter from './routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    authRouter,
    {
      path: '*',
      component: () => import('../pages/404.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (from.path === '/' && to.matched.some(record => record.meta.disableDirectUrl)) {
    next({ name: 'auth-log-in'});
  } else {
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
