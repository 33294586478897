import Vue from 'vue';
import Vuex from 'vuex';
import actions from '@/store/actions';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import VueLoading from 'vuejs-loading-plugin';

Vue.use(VueLoading)
Vue.use(Vuex);

const STATE = {
  step: 1,
  login: null,
  password: null,
  username: null,
  languages: {
    ru: 'Русский язык',
    az: 'Aзәрбајҹан дили',
  },
  language: 'ru',
  userId: null,
  maskedEmail: null,
  maskedPhone: null,
  code: [null, null, null, null, null, null],
  errors: {},
  submitError: null,
  loading: false,
  needRelogin: false,
  status: {
    isNew: false,
    hasPhone: false,
    hasMail: false,
  },
  user: null,
};

export default new Vuex.Store({
  state: STATE,
  getters,
  mutations,
  actions,
});

