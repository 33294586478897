<template>
  <div id="app">
    <div class="b-layout">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Login from './pages/Login.vue';

export default {
  name: 'App',
  components: {
    Login
  },
  beforeCreate() {
    this.$loading(true);
    this.$store.dispatch('apiCheckToken')
      .then(() => window.location.href = '/')
      .catch(() => this.$loading(false));
  }
}
</script>

<style lang="scss">
@import "styles/index.scss";
@import "styles/lectorix.scss";
</style>
