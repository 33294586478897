import {debounce} from 'throttle-debounce';
import client from '@/client';
import router from '@/router/index';
import i18n from '@/i18n';
import {getCookie} from '@/common/cookie';

export default {
  reset: ({ commit }) => {
    commit('reset');
  },
  setStep: ({ commit, state }, data) => {
    commit('step', data);
  },
  setLogin: ({ commit, state }, data) => {
    commit('login', data);
  },
  setPassword: ({ commit, state }, data) => {
    commit('password', data);
  },
  setUsername: ({ commit, state }, data) => {
    commit('username', data);
  },
  setLanguage: ({ commit, state }, data) => {
    commit('language', data);
  },
  setCode({ commit, state, dispatch }, { index, value }) {
    let { code } = state;
    if (
        !code ||
        !Array.isArray(code) ||
        index < 0 ||
        typeof value === 'undefined'
    ) {
      return;
    }
    code = code.map((item, i) => {
      if (i !== index) {
        return item;
      }
      return value;
    });
    commit('code', code);
    dispatch('setError', {
      errorId: 'code',
      message: null,
    });
  },
  setError: function ({ commit, state, dispatch }, { errorId, message }) {
    let { errors } = state;
    if (!errors || !errorId) {
      return;
    }
    errors[errorId] = message;
    commit('errors', Object.assign({}, errors));
  },
  apiCheckToken() {
    return client.checkToken();
  },
  /*
   Requests
   */
  apiUserExists: debounce(100, async ({ dispatch, commit, state }) => {
    commit('loading', true);

    try {
      const { data } = await client.userExists(state.login);

      const { id, fullName, email, isNew, hasEmail, hasPhone, externalInstanceName } = data;

      if (null !== externalInstanceName) {
        const { item } = await client.getSuitableInstanceHost(externalInstanceName);
        const uri = (getCookie('previousPage') !== undefined) ? getCookie('previousPage') : '';
        window.location.href = 'https://' + item.host + uri
      }

      commit('status', {
        isNew,
        hasEmail,
        hasPhone,
      });

      dispatch('setError', {
        errorId: 'login',
        message: null,
      });

      if (fullName) {
        commit('username', fullName);
      }

      if (email) {
        commit('maskedEmail', email);
      }

      if (id) {
        commit('setUserId', id);
      }

      // If user has no password:
      if (isNew) {
        dispatch('recoverPassword');
      } else {
        dispatch('setStep', 2);
      }
    } catch (error) {
      if (error.status === 404) {
        dispatch('setError', {
          errorId: 'login',
          message: i18n.t('auth---User does not exist'),
        });
      } else if (error.status >= 400) {
        commit('submitError', i18n.t('auth---Something went wrong'));
      } else {
        commit('submitError', error?.data?.message ?? error);
      }
    }

    commit('loading', false);
  }),
  recoverPassword: debounce(100, ({ dispatch, state }) => {
    const { isNew, hasPhone, hasEmail } = state.status;

    if (hasEmail) {
      const route = isNew ? 'auth-email' : 'auth-reset-email';
      dispatch('emailRecover', route);
    } else if (hasPhone) {
      dispatch('smsRecover', 'auth-reset-sms');
    } else {
      router.push({ name: 'auth-reset-admins' });
    }
  }),
  apiLogin: ({ dispatch, commit, state }, code) => {
    commit('loading', true);

    return new Promise((resolve, reject) => {
      client
        .login(state.login, state.password, code)
        .then((response) => {
          if (response.item.step === 'confirm-code') {
            router.push({ name: 'auth-verification-code'});
          }

          if (response.item.step === 'missing-email') {
            router.push({ name: 'auth-verification-missing-email'});
          }

          if (response.item.step === 'success') {
            let location = '/';
            const redirectPath = getCookie('previousPage');
            if (typeof redirectPath === 'string' || redirectPath instanceof String) {
              location = redirectPath;
            }
            window.location.href = location;
          }

          resolve()
        })
        .catch(error => {
          let errorKey = error.data.error?.key;

          switch (true) {
            case errorKey === 'invalid_code':
              dispatch('setError', {
                errorId: 'code',
                message: i18n.t('auth---Wrong code'),
              });
              break;

            case errorKey === 'no_active_code':
              dispatch('setError', {
                errorId: 'code',
                message: i18n.t('auth---No active code') + '. ' + i18n.t('auth---You need to log in again.'),
              });
              commit('needRelogin', true);
              break;

            case errorKey === 'attempts_exceeded':
              dispatch('setError', {
                errorId: 'code',
                message: i18n.t('auth---Too many attempts') + '. ' + i18n.t('auth---You need to log in again.'),
              });
              commit('needRelogin', true);
              break;

            case error.status === 400:
              dispatch('setError', {
                errorId: 'password',
                message: i18n.t('auth---Wrong password'),
              });
              break;

            case error.status === 429:
              dispatch('setError', {
                errorId: 'password',
                message: i18n.t('auth---Too many attempts'),
              });
              break;

            case error.status === 500 || error.status === 404:
              commit('submitError', i18n.t('auth---Something went wrong'));
              break;

            default:
              commit('submitError', error.data.error.message);
          }

          reject(errorKey)
        })
        .finally(() => commit('loading', false));
    });
  },
  emailRecover: debounce(100, ({ commit, state }) => {
    commit('loading', true);
    client
      .passwordRecover(parseInt(state.userId))
      .then(({ data }) => {
        const { maskedContact } = data;

        commit('maskedEmail', maskedContact);
        router.push({ name: 'auth-reset-email' });
      })
      .catch((error) => {
        if (error.status === 500) {
          commit('submitError', i18n.t('auth---Something went wrong'));
        } else {
          commit('submitError', error.data.message);
        }
      })
      .finally(() => commit('loading', false));
  }),
  smsRecover: debounce(100, ({ commit, state }, route) => {
    commit('loading', true);
    client
      .passwordSms(parseInt(state.userId))
      .then(({ data }) => {
        const { maskedContact } = data;

        commit('maskedPhone', maskedContact);
        router.push({ name: route });
      })
      .catch((error) => {
        const status = error.status;

        if (status === 404) {
          router.push({ name: 'auth-reset-admins' });
        } else if (error.status >= 400) {
          commit('submitError', i18n.t('auth---Something went wrong'));
        } else {
          commit('submitError', error.data.message);
        }
      })
      .finally(() => commit('loading', false));
  }),
  apiPasswordSet: debounce(100, ({ dispatch, commit, state }, { userId, code, password }) => {
    commit('loading', true);
    commit('password', password);
    client
      .passwordSet(userId, code, password)
      .then(() => {
        let location = '/';
        const redirectPath = getCookie('previousPage');
        if (typeof redirectPath === 'string' || redirectPath instanceof String) {
          location = redirectPath;
        }
        window.location.href = location;
      })
      .catch((error) => {
        if (error.status === 400 ){
          commit('submitError', i18n.t('auth---Wrong code'));
        } else if (error.status === 500 || error.status === 404) {
          commit('submitError', i18n.t('auth---Something went wrong'));
        } else {
          commit('submitError', error.data.message);
        }
      })
      .finally(() => commit('loading', false));
  }),
  apiProfileUpdate: debounce(100, ({ commit, state }) => {
    commit('loading', true);
    const { language } = state;
    const { email, phone } = state.user;
    client
      .profileUpdate({
        email,
        phone,
        language,
      })
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        if (error.status === 500) {
          commit('submitError', i18n.t('auth---Something went wrong'));
        } else {
          commit('submitError', error.data.message);
        }
      })
      .finally(() => commit('loading', false));
  }),
  async getVerificationCodeDelay({commit, state}) {
    const { login } = state;
    try {
      return await client.getVerificationCodeDelay(login);
    } catch (error) {
      if (error.status === 500) {
        commit('submitError', i18n.t('auth---Something went wrong'));
      } else {
        commit('submitError', error.data.message);
      }
      throw error;
    }
  },
  async resendVerificationCode({ commit, state }) {
    const { login, userId } = state;
    try {
      commit('loading', true);
      const result = await client.resendVerificationCode(login, userId);
      commit('loading', false);
      return result;
    } catch(error) {
      if (error.status === 500) {
        commit('submitError', i18n.t('auth---Something went wrong'));
      } else {
        commit('submitError', error.data.message);
      }
    }
  },

}

