import axios from 'axios';

export default {
  userExists(login) {
    return fetch({
      method: 'POST',
      url: '/api/v2/user/exists',
      data: { login }
    });
  },
  login(login, password, code) {
    let data = {
      login,
      password,
      code
    };

    return fetch({
      method: 'POST',
      url: '/s/auth/api/e/user/auth',
      data,
    });
  },
  passwordRecover(userId) {
    return fetch({
      method: 'POST',
      url: '/api/v2/auth/password/recover',
      data: { userId },
    });
  },
  passwordSms(userId) {
    return fetch({
      method: 'POST',
      url: '/api/v2/auth/password/sms',
      data: { userId },
    });
  },
  confirmSms(userId, code) {
    return fetch({
      method: 'GET',
      url: '/api/v2/user/confirm',
      params: { userId, code},
    });
  },
  passwordSet(id, code, password) {
    return fetch({
      url: '/s/auth/api/e/user/password',
      data: {
        id,
        code,
        password
      },
    });
  },
  profileUpdate(email, phone, language) {
    return fetch({
      url: '/api/v2/auth/password/set',
      params: {
        email,
        phone,
        language,
      },
    });
  },
  checkToken() {
    return fetch({
      url: '/s/auth/api/e/user/token/check/api',
      method: 'GET',
    });
  },
  getSuitableInstanceHost(instanceName) {
    return fetch({
      url: '/s/sync/api/e/instance/' + instanceName,
      method: 'GET',
    });
  },
  getVerificationCodeDelay(login) {
    return fetch({
      url: '/s/auth/api/e/user/verification-code-delay',
      method: 'GET',
      params: {
        login
      }
    });
  },
  resendVerificationCode(login, userId) {
    return fetch( {
      url:'/s/auth/api/e/user/verification-code-resend',
      method: 'POST',
      data: {
        login,
        userId
      }
    })
  }
};

const client = axios.create();

async function fetch(request) {
  const axiosRequest = {
    method: 'POST',
    ...request,
  };

  try {
    const response = await client.request(axiosRequest);

    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err.response);
  }
}
